<template>
  <div class="meetSetting">
    <div class="header" @click="go('userInfo')">
      <div class="headerBox">
        <img class="img" :src="userInfo.avatar?userInfo.avatar:require('../../../assets/images/headerImg.png')" alt="" />
        <div class="info">
          <div class="name">{{ userInfo.realName }}</div>
        </div>
      </div>

      <img class="rightImg" src="../../../assets/images/right.png" alt="" />
    </div>
    <div class="item" @click="go('meetSetInfo')">
      <div class="tit">{{ $t("login.selfNumber") }}</div>
      <div class="box">
        <div class="text">{{ userInfo.conferenceNo }}</div>
        <img class="rightImg" src="../../../assets/images/right.png" alt="" />
      </div>
    </div>
    <div class="meetInfo">
      <div class="meetBox">
        <!-- v-if="userInfo.packageType===2" -->
        <div class="box">
          <img class="img" :class="userInfo.packageType === 1?'imgFilter':''" src="../../../assets/images/infoTit.png" alt="" />
          <div class="textBox">
            <div class="type" v-if="userInfo.packageType === 2">
              {{ $t("login.edition") }}
            </div>
            <div class="type" v-else>{{ $t("login.free") }}</div>
            <div class="info" v-if="userInfo.packageType === 2">{{ userInfo.enterpriseName}}</div>
             <a class="infoCall" v-else href="tel:400-114-9100">{{ $t("login.callService") }}</a>
          </div>
        </div>

        <div class="text">
          <span class="left">{{ $t("login.singleTime") }}</span>
          <span class="right"
            >{{userInfo.packageType=== 1 ? userInfo.usefulLife + $t('login.mintue'): $t('login.unlimited')}}</span
          >
        </div>
        <div class="text bottom">
          <span class="left">{{ $t("login.maxNumber") }}</span>
          <span class="right"
            >{{ userInfo.thresholdValue }}{{ $t("meeting.ren") }}</span
          >
        </div>
      </div>
    </div>

    <div class="item">
      <div class="tit">{{ $t("seeting.phone") }}</div>
      <div class="text">{{ userInfo.phone }}</div>
    </div>

    <div class="item">
      <div class="tit">{{ $t("seeting.mailbox") }}</div>
      <div class="text">{{ userInfo.email }}</div>
    </div>
    <div class="item" @click="go('changePassword')">
      <div class="tit">{{ $t("login.password") }}</div>
      <img class="rightImg" src="../../../assets/images/right.png" alt="" />
    </div>

    <div class="signOut" @click="signOut()">{{ $t("login.logOut") }}</div>
    <div class="signOut back" @click="back()">{{ $t("common.back") }}</div>
  </div>
</template>

<script>
import { removeLocalUserInfo } from "@/utils/auth"
export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {
    userInfo() {
      console.log(this.$store.state.user.userInfo);
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    go(path) {
      this.$router.push({ name: path });
    },
    signOut() {
      // removeLocalUserInfo()//移除本地缓存
      // this.$router.push({ path: "login" });
      sdk.auth.logout().then(() => {
        removeLocalUserInfo()//移除本地缓存
        this.$router.replace({ path: "/" });
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.meetSetting {
  width: 100%;
  padding-bottom:50px;
  background: #fafafa;
  .rightImg {
    width: 32px;
    height: 32px;
    margin-left: 16px;
  }
  .text {
    font-size: 28px;
    color: #999;
  }
  .header {
    height: 200px;
    margin-top: 16px;

    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .headerBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        width: 110px;
        height: 110px;
      }
      .info {
        margin-left: 20px;
      }
      .name {
        font-size: 32px;
        color: #000;
      }
      .tel,
      .email {
        font-size: 28px;
        color: #999;
      }
    }
  }
  .meetInfo {
    width: 100%;
    height: 288px;
    background: #ffffff;
    margin-top: 16px;
    box-sizing: border-box;
    padding: 24px 30px;
  }
  .meetBox {
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    .textBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      position: absolute;
      box-sizing: border-box;
      padding:0 24px;
      left: 0;
      top: 0;
      font-size: 28px;
      .type{
        color:#fff;
      }
      .info{
       color: #1AB370;
      }
      .infoCall{
        color:#666;
      }
    }
    .img {
      width: 100%;
      height: 80px;
      
    }
    .imgFilter{
      filter: grayscale(100%);
      filter: gray;
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      padding: 0 24px;
    }
    .left {
      font-size: 28px;
      color: #666;
    }
    .right {
      font-size: 28px;
      color: #333;
    }
    .bottom {
      height: 81px;
      border-top: 1px solid #e5e5e5;
    }
  }

  .item {
    background: #fff;
    margin-top: 16px;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .box {
      display: flex;
      align-items: center;
    }
    .tit {
      font-size: 32px;
      color: #333;
    }
  }
  .signOut {
    margin-top: 80px;
    background: #fff;
    text-align: center;
    line-height: 88px;
    color: #ff4d4f;
    font-size: 32px;
    width: 622px;
    height: 88px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    margin-left: 64px;
  }
  .back {
    color: #333;
    margin-top: 32px;
  }
}
</style>
